.right-side-content {
  .background-right-container {
    background: #0D0D0D;
    border: none;
    .top-content-right {
      border-bottom: 1px solid transparent;
      border-image-source: linear-gradient(90deg, rgba(255, 255, 255, 0.048) 0%, rgba(255, 255, 255, 0.24) 50%, rgba(255, 255, 255, 0.048) 100%);
      border-image-slice: 1;
      background: transparent;
      .left-side {
        h5 {
          color: #FFFFFF;
        }
      }
    }
    .asset-duration-cont {
      .start-end-time {
        color: #BFBFBF;
      }
      .video-ads-time{
        color: #BFBFBF;
        span {
          color: #FFFFFF;
        }
      }
    }
    .bottom-content-right {
      background: transparent;
      .tabs {
        .channel-category-tab {
          background: linear-gradient(to bottom, #1a1a1a, #2a2a2a);
        }
        .channel-category-tab li a {
          color: white;
        }
        .channel-category-tab li.active a {
          background-color: #0D0D0D;
        }
        .channel-category-tab li:not(.active) a:hover {
          background-color: #555;
        }
      }
      .ads-table {
        background: #262728;
        border: 0.5px solid rgba(68, 70, 73, 1);
        table {
          thead {
            tr {
              th {
                .header-icon {
                  filter: invert(1);
                }
              }
            }
          }
          tbody {
            tr {
              background: transparent !important;
              td {
                background: transparent;
                select {
                  color: #FFFFFF;
                  border: none;
                  background: rgba(255, 255, 255, 0.06);
                  option {
                    background: #262728;
                  }
                }
                .react-time-picker {
                  background: rgba(255, 255, 255, 0.06);
                  .react-time-picker__wrapper {
                    border: none;
                  }
                }
                .ads-duration {
                  background: rgba(255, 255, 255, 0.06);
                }
                .table-action-group {
                  background: rgba(255, 255, 255, 0.06);
                }
              }
            }
          }
        }
      }
    }
  }
}

.react-time-picker {
  width: 100%;
  text-align: center;
  padding: 6px;
  border-radius: 5px;
}
.react-time-picker--disabled {
  background: transparent;
}
