

.action-category {
    width: 100%;
    margin-top: 34px;

    ul {
        justify-content: end;
        padding: 0;
        list-style: none;
        display: flex;
        gap: 24px;

        li {
            display: inline-block;
            position: relative;

            a {
                color: #999999 !important;
                font-family: Roboto;
                font-size: 16px;
                font-style: normal;
                font-weight: 400;
                line-height: 100%;
                display: inline-flex;
                padding: 8px 16px;
                align-items: flex-start;
                gap: 8px;
                box-shadow: 0px 4px 16px 0px rgba(153, 153, 153, 0.08);
            }

            &.active {
                background: #D22F26;
                border-radius: 6px;
                border: 0.5px solid #D22F26;

                a {
                    box-shadow: 0px 4px 16px rgba(153, 153, 153, 0.08);
                    color: #fff;
                    font-weight: 500;
                    background: #D22F26;
                }
            }
        }
    }

    .disable a {
        background-color: #00000042;
        cursor: not-allowed;
    }
}

.bread-action ul {
    margin-bottom: 0;
}

.table-checkbox {
    table {
        width: 100%;
        border-collapse: collapse;


        thead {
            tr {
                &::before {
                    // background: #EFE2E2;
                }
            }
        }

        tr {
            position: relative;

            &.active {
                &::before {
                    background-color: #D22F26;
                }
            }

            &::before {
                content: "";
                display: block;
                position: absolute;
                width: 5px;
                height: 100%;
                background-color: #fff;
            }
        }

        th,
        td {
            text-align: left;
            vertical-align: top;
        }

        td {
            font-size: 14px;
            color: #333333;
            padding: 15px 20px; /* Default padding for general cells */
            border-bottom: 1px solid #EFE2E2;

            /* Remove padding for right-side columns */
            &:nth-child(n+6) {
                padding: 0; /* Removes padding for Transcode, Validate, and Publish */
            }

            &:last-child {
                text-align: center;
            }
        }

        th {
            background: #1c1919;
            font-weight: 500;
            font-size: 12px;
            line-height: 14px;
            letter-spacing: 0.02em;
            text-transform: uppercase;
            color: #927F7F;
            padding: 10px 20px;
            vertical-align: top;

            /* Remove padding for right-side columns */
            &:nth-child(n+6) {
                padding: 0; /* Removes padding for Transcode, Validate, and Publish */
            }
        }

        input[type="checkbox"] {
            display: block;
            opacity: 0;
            position: absolute;

            &+label {
                position: relative;
            }

            &+label::before {
                content: "";
                display: inline-block;
                position: absolute;
                width: 20px;
                height: 20px;
                border-radius: 2px;
                border: 1.5px solid #A9ACAE;
                left: 0px;
                top: 0px;
            }

            &:checked+label::before {
                background-color: #D22F26;
                border-color: #D22F26;
            }

            &:checked+label::after {
                content: "";
                display: inline-block;
                color: #FFFFFF;
                position: absolute;
                left: 5px;
                top: 6px;
                font-weight: bold;
                background: url(/assets/images/checkbox-active.svg) no-repeat;
                width: 18px;
                height: 16px;
            }

            &:disabled+label::before {
                background-color: #b6b6b6; /* Change the background color */
                color: #777; /* Change the text color */
                border: 5px solid #ccc; /* Change the border color */
                cursor: not-allowed;
            }
        }

        select {
            background: transparent;
        }
    }

    /* Removing padding specifically for the Transcode, Validate, and Publish columns */
    .table-checkbox th:nth-child(6),
    .table-checkbox td:nth-child(6),
    .table-checkbox th:nth-child(7),
    .table-checkbox td:nth-child(7),
    .table-checkbox th:nth-child(8),
    .table-checkbox td:nth-child(8) {
        padding: 0 !important; /* Removes padding */
    }

        .complete {
            margin: auto;
            width: 170px;
            padding: 6px;
            border-radius: 20px;
            background: #73DF5C;
            font-family: Roboto;
            font-size: 18px;
            font-weight: 400;
            line-height: 14px;
            letter-spacing: 0em;
            text-align: left;
            color: #000;
      
        }

        .error {
            margin: auto;
            width: 170px;
            padding: 6px;
            border-radius: 20px;
            background: #EF4444;
            font-family: Roboto;
            font-size: 17px;
            font-weight: 400;
            line-height: 14px;
            letter-spacing: 0em;
            text-align: left;
            color: #000;
            cursor: pointer;
        }

        .pending {
            margin: auto;
            width: 170px;
            padding: 6px;
            border-radius: 20px;
            background: #C5E7F7;
            font-family: Roboto;
            font-size: 18px;
            font-weight: 400;
            line-height: 14px;
            letter-spacing: 0em;
            text-align: left;
            color: #000;
        }

        .processing {
            margin: auto;
            width: 170px;
            padding: 6px;
            border-radius: 20px;
            background: #FABD40;
            font-family: Roboto;
            font-size: 18px;
            font-weight: 400;
            line-height: 14px;
            letter-spacing: 0em;
            text-align: left;
            color: #000;
        }

        .OverRiden {
            cursor: pointer;
            margin: auto;
            width: 170px;
            padding: 6px;
            border-radius: 20px;
            background: #F9801E;
            font-family: Roboto;
            font-size: 18px;
            font-weight: 400;
            line-height: 14px;
            letter-spacing: 0em;
            text-align: left;
            color: #000;

        img {
            width: 18px;
        }
    }
}

.left-align {
    text-align: left !important;
}

.right-align {
    text-align: right !important;
}

// td {
//     padding: 10px;
// }

th {
    /* padding: 10px; */
}

.dropdownfilter {
    font-family: Roboto;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: left;
}

.dropdownfilter.active {
    background: #1a1717;
}

.transoption {
    font-size: 12px;
    font-weight: 500;
    border: none;
    width: 8rem;
    transition: all .6s;
    padding: 8px 0px;
    box-shadow: 0px 4px 16px rgba(153, 153, 153, 0.08);
    color: #fff;
    background: #121212;

    &:hover,
    &:first-of-type {
        background: #393737;
    }
}

.refresh-button {
    float: left;
    font-size: 12px;
    font-weight: 500;
    padding: 7px;
    // border: 1px solid #D22F26;
    border-radius: 6px;
    background-color: #444649;
    border-color: #444649 ;

    &:hover {
        color: white;
    }

    img {
        width: 30px;
        height: 30px;
    }
}

.rotate {
    transition: transform 0.5s ease-in-out;
}


// .filter-icon {
//     margin-left: 8px;
//     cursor: pointer;
//   }
//   th {
//     padding: 10px 15px;
//     vertical-align: middle;
//   }
//   th div {
//     display: inline-flex;
//     align-items: center;
//   }
  .right-side {
    .search-box {
      display: flex;
      align-items: center;
      padding: 3px;
      border-radius: 7px;
      width: 380px;
      background-color: #ffffff; // Default for light mode
    
  
      .search-input {
        flex: 1;
        padding: 0;
        border: none;
        outline: none;
        font-size: 1rem;
        background: transparent;
        color: var(--text-color); // Adapt to theme
      }
  
      .search-icon {
        width: 20px;
        height: 20px;
        filter: var(--icon-filter); // Adapt icon color based on theme
      }
    }
  
    // Dark mode styles
    &.dark {
      .search-box {
        background-color: #2d2d2d; // Dark background for dark mode
  
        .search-input {
          color: #ffffff; // Text color for dark mode
        }
  
        .search-icon {
          filter: invert(100%) sepia(0%) saturate(0%) hue-rotate(180deg) brightness(200%) contrast(90%);
          // Inverts the icon color to make it visible on dark backgrounds
        }
      }
    }
  }
  
.buttons-box {
    border: 1px solid #FFFFFF29; 
   border-radius: 6px;
 }


 

 .actiontable{
    background-color: #121212 !important;
    
 }
 .loader-container {
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5); /* Dark background to make loader visible */
    z-index: 9999;
    flex-direction: column;
    color: #fff;
  }
  
  .loader {
    border: 4px solid #f3f3f3; /* Light grey background */
    border-top: 4px solid red; /* Red color for the spinning part */
    border-radius: 50%;
    width: 40px;
    height: 40px;
    animation: spin 1s linear infinite;
    margin-bottom: 15px;
  }
  
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
  .pagination-arrow .disabled {
    pointer-events: none;  /* Prevent clicks */
    opacity: 0.5;          /* Make the arrow appear faded */
  }

  input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  -moz-appearance: textfield; /* For Firefox */
}
  
.custom-tooltip-text {
    visibility: hidden;
    opacity: 0;
    transition: opacity 0.3s;
  }
  
  div:hover .custom-tooltip-text {
    visibility: visible;
    opacity: 1;
  }
  .pagination-arrow button {
    background: transparent;
    border: none;
    padding: 5px;
    cursor: pointer;
  }
  
  .pagination-arrow button:disabled {
    cursor: not-allowed;
    opacity: 0.5;
  }
  
  .pagination-arrow button img {
    width: 8px;
    height: 8px;
  }
  