.monitor-grid-view {
    // padding: 0px;
    border-radius: 2px;
    // background-color: #161313; 
    // background-color: #b71313; 
    padding: 0;
    height: 100vh; 
    overflow: hidden; 

    .monitor-video-grid {
        display: grid;
        grid-template-columns: repeat(4, 1fr); 
        grid-template-rows: repeat(3, 1fr);
        gap: 6px; 
      
        @media (max-width: 1200px) {
            grid-template-columns: repeat(3, 1fr);
          }
      
          @media (max-width: 768px) {
            grid-template-columns: repeat(2, 1fr);
          }
      
          @media (max-width: 600px) {
            grid-template-columns: 1fr;
          }

        .channel-section {
            width: 100%;
            height: 100%;
            border-radius: 12px;
            box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
            transition: transform 0.3s ease, box-shadow 0.3s ease;
            border: 1px solid #3c3939;
            padding: 3px; 
            &:hover {
                transform: translateY(-5px);
                box-shadow: 0 8px 16px rgba(0, 0, 0, 0.3);
            }

            .channel-name {
                text-align: center;
                font-size: 16px; 
                font-weight: 600;
                color: #ffffff; 
                // margin-bottom: 5px; 
                padding-top: 5px; 
                
            }

            .monitor-video-player {
                background: #000000;
                border-radius: 4px;
                overflow: hidden;
                position: relative;
                width: 100%;
                margin-top: -2px;
                display: flex;
                justify-content: center;
                align-items: center;
                height: 26vh; 
              
                @media (max-width: 1200px) {
                  height: 40vh; 
                }
              
                @media (max-width: 768px) {
                  height: 35vh;
                }
              
                @media (max-width: 600px) {
                  height: 40vh;
                }
              
                .video-element,
                .ReactHlsPlayer {
                  position: absolute;
                  top: 0;
                  left: 0;
                  width: 100%;
                  height: 100%;
                  object-fit: cover;
                  border: none;
                }
              }
              

            .no-video-message {
                color: #cccccc;
                font-size: 0.9rem;
                text-align: center;
                margin-top: 1px;
            }

            video,
            .react-hls-player {
                width: 100%;
                height: 100%;
        border-radius: 4px;
        object-fit: cover;
            }
        }
    }
   
    
}
