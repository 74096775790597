.monitor-video-player {
    margin-top: 30px;
    border-radius: 6px;
    overflow: hidden;
    background: linear-gradient(#0D0D0D, #333333);
}
.monitor-video-title {
    padding: 8px 16px;
    background: #2E3033;
    line-height: 16px;
    vertical-align: middle;
    > div{
        &:first-child {
            font-size: 16px;
            color:#999999;
            font-weight: 500;
        }
        font-size: 14px;
        color: #FFFFFF;
    }
}
.monitor-video-info {
    padding: 8px 16px 16px 16px;
    >div{
        &:first-child {
            margin-top: 0;
        }
        > div:first-child {
            color: #999999;
            font-size: 13px;
        }
        margin-top: 8px;
        color: #D0D0D0;
    }
}
// .custom-tooltip {
//     display: none;
//   }
.sidebar-menu li[title] {
    position: relative;
  }
  
  .sidebar-menu li[title]::after {
    content: none;
    display: none;
  }
.monitor-sidebar {
    tbody tr{
        td{
            &:first-child {
                background: url("../../assets/images/check-box.png") no-repeat 10px 8px;
                padding-left: 25px;
            }
        }
    }
}
