.scheduling-loop-content-body {
  padding: 25px 10px 0px 10px !important;
}
.schedule-title-header {
    font-size: 32px !important;
    padding-left: 0px !important;
}
.schedule-loop-view {
    padding-left: 10px;
    padding-bottom: 20px;
}
.schedule-loop-view .left {
    // width: 224px !important;
    width: 20% !important;
}

.SchedulingDetail .program-list {
    padding: 8px;
    margin-left: 0px !important;
    margin-top: 30px !important;
}
.SchedulingDetail .program-header {
    font-size: 20px !important;
}
// customized

.border-middle-container {
  padding: 10px;
  height: 100%;
}
.background-right-container {
  height: 710px;
}
.channel-category-tab {
  display: flex;
  align-items: center;
  list-style-type: none;
  padding: 4px;
  margin: 0;
  border-radius: 20px;    
}
.channel-category-tab li {
  flex: 1;
  text-align: center;
}
.channel-category-tab li a {
  display: block;
  padding: 5px 20px;
  text-decoration: none;
  border-radius: 20px;
  transition: background-color 0.3s, color 0.3s;
}
.channel-category-tab li.active a {
  padding: 5px;
  color: white;
  border: 1px solid #D22F26;
}
.ads-table {
  height: 370px;
  overflow-y: auto;
}
.ads-table::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
  border-radius: 10px;
}
.ads-table::-webkit-scrollbar {
  width: 4px;
}
.ads-table::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);
  background-color: #D22F26;
}
.ads-table-btn {
  color: #f16660;
  cursor: pointer;
  font-size: 16px;
}


.table-content2 .table-icon,
.table-content2 .table-adbreaks-count {
  transition: opacity 0.3s ease, max-height 0.3s ease;
  overflow: hidden; /* Prevent layout shifts */
}
.table-content2 .table-icon {
  opacity: 0;
  max-height: 0; /* Start with no height */
  visibility: hidden;
  top: 0px;
  right: 0px;
}
.table-content2 .table-adbreaks-count {
  opacity: 1;
  max-height: 50px; /* Adjust based on content height */
  visibility: visible;
  top: 0px;
  right: 0px;
}
.dragabble:hover .table-content2 .table-icon {
  opacity: 1;
  max-height: 50px; /* Matches content height */
  visibility: visible;
}
.dragabble:hover .table-content2 .table-adbreaks-count {
  opacity: 0;
  max-height: 0;
  visibility: hidden;
}


.table-content2 .table-adbreaks-count span{
  padding: 4px 7px;
  background: #D22B26;
  border-radius: 22px;
  color: #fff;
  font-size: 11px;
}
.table-content2 .schedule-time{
  font-size: 11px;
}
.table-content2 .title-schedule-container{
  width: 50%;
}
.table-content2 .program-title{
  overflow: hidden;
  text-overflow: ellipsis;
  width: 70%;
  white-space: nowrap;
}
.ads-table .table tr td,
.ads-table .table tr th {
  border-bottom: none !important;
}
.ads-table .table tr td select{
  width: 100%;
  background: transparent;
  padding: 9px;
  border-radius: 5px;
}
.ads-table .table tr td .ads-duration{
  width: 100%;
  padding: 7px 11px;
  border-radius: 5px;
  text-align: center;
}
.ads-table .table tr td .table-action-group{
  width: 100%;
  padding: 7px;
  border-radius: 5px;
  text-align: center;
}
.react-time-picker {
  width: 100%;
  text-align: center;
  padding: 6px;
  border-radius: 5px;
}

.dark-theme {
  .SchedulingDetail {
    .program-header {
        color: #FFFFFF;
    }
    .treelist {
        li {
            span {
                color: #BFBFBF;
            }
        }
    }
    .channel-name {
      color: #FFFFFF;
    }
    .program-name {
        color: #FFFFFF;
    }
    .left {
      .list-box {
        .channel-listing-cseq {
          .nexted-list {
            // border: 1px solid #3c3c3c;
            // border-radius: 7px;
            // background: #1a1a1a;
            background: transparent;
            border: none;
          }
          .nexted-list-item:hover {
            background: #5D5D5D;
          }
        }
        .search-container {
          input:not(:invalid) {
            border: 1px solid transparent !important; /* This sets the border width */
            border-image-source: linear-gradient(95deg, #2E3033 3.21%, #8A9099 50.03%, #2E3033 95.24%) !important;
            border-image-slice: 1 !important;
          }
        }
      }
    }
    .right {
      .border-right-container {
        border: 1px solid transparent;
        border-image-source: linear-gradient(95deg, #2E3033 3.21%, #8A9099 50.03%, #2E3033 95.24%);
        border-image-slice: 1;
        background: transparent;
        .top-content{
          .right-side {
            a {
              img {
                filter: invert(0);
              }
            }
          }
        }
        .bottom-content {
          .middle-content {
            .border-middle-container {
              border: 1px solid #444649;
              background: transparent;
              .middle-down {
                .content-wrapper {
                  .scheduling-table {
                    .row {
                      .grid-list-container {
                        border-radius: 10px;
                        background: rgba(13, 13, 13, 0.44);
                        background-image:linear-gradient(to right, #161616c7 5px, transparent 1px), linear-gradient(#3b3b3b 1px, transparent 1px);
                        background-size: 30px 35px;
                      }
                      .time-slots-container {
                        .time-slot {
                          p {
                            color: #999999;
                          }
                        }
                      }
                    }
                    tbody {
                      tr {
                        td:hover{
                          border: 4px solid rgba(0, 0, 0, 0.9);
                        }
                      }
                    }
                  }
                }
              }
            }
          }
          .right-side-content {
            .background-right-container {
              background: #0D0D0D;
              border: none;
              .top-content-right {
                border-bottom: 1px solid transparent;
                border-image-source: linear-gradient(90deg, rgba(255, 255, 255, 0.048) 0%, rgba(255, 255, 255, 0.24) 50%, rgba(255, 255, 255, 0.048) 100%);
                border-image-slice: 1;
                background: transparent;
                .left-side {
                  h5 {
                    color: #FFFFFF;
                  }
                }
              }
              .asset-duration-cont {
                .start-end-time {
                  color: #BFBFBF;
                }
                .video-ads-time{
                  color: #BFBFBF;
                  span {
                    color: #FFFFFF;
                  }
                }
              }
              .bottom-content-right {
                background: transparent;
                .tabs {
                  .channel-category-tab {
                    background: linear-gradient(to bottom, #1a1a1a, #2a2a2a);
                  }
                  .channel-category-tab li a {
                    color: white;
                  }
                  .channel-category-tab li.active a {
                    background-color: #0D0D0D;
                  }
                  .channel-category-tab li:not(.active) a:hover {
                    background-color: #555;
                  }
                }
                .ads-table {
                  background: #262728;
                  border: 0.5px solid rgba(68, 70, 73, 1);
                  table {
                    thead {
                      tr {
                        th {
                          .header-icon {
                            filter: invert(1);
                          }
                        }
                      }
                    }
                    tbody {
                      tr {
                        background: transparent !important;
                        td {
                          background: transparent;
                          select {
                            color: #FFFFFF;
                            border: none;
                            background: rgba(255, 255, 255, 0.06);
                            option {
                              background: #262728;
                            }
                          }
                          .react-time-picker {
                            background: rgba(255, 255, 255, 0.06);
                            .react-time-picker__wrapper {
                              border: none;
                            }
                          }
                          .ads-duration {
                            background: rgba(255, 255, 255, 0.06);
                          }
                          .table-action-group {
                            background: rgba(255, 255, 255, 0.06);
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

.SchedulingDetail {
  .left {
    .list-box {
      padding: 8px !important;
      height: 742px;
      .channel-listing-cseq {
        .nexted-list {
          border: 1px solid #d22f26;
          border-radius: 7px;
          background: #ffffff;
        }
        .nexted-list-item:hover {
          background: #efe2e2;
        }
      }
    }
  }
  .right {
    .border-right-container {
      background: #efe2e2;
      .top-content{
        .right-side {
          a {
            img {
              filter: invert(1);
            }
          }
        }
      }
      .bottom-content {
        .middle-content {
          .border-middle-container {
            border: 1px solid #d22f26;
            background: #FFFFFF;
            .middle-down {
              .content-wrapper {
                .scheduling-table {
                  .row {
                    .grid-list-container {
                      border-radius: 10px;
                      background: rgb(243 243 243);
                      background-image: linear-gradient(to right, rgb(243 243 243 / 100%) 5px, transparent 1px), linear-gradient(#303030 1px, transparent 1px);
                      background-size: 30px 35px;
                    }
                  }
                  tbody {
                    tr {
                      td:hover{
                        border: 4px solid #FFFFFF;
                      }
                    }
                  }
                }
              }
            }
          }
        }
        .right-side-content {
          .background-right-container {
            .top-content-right {
              background: #FFFFFF;
            }
            .bottom-content-right {
              background: #FFFFFF;
              height: 500px;
              .tabs {
                .channel-category-tab li.active a {
                  background-color: #D22F26;
                  color: #FFFFFF;
                }
                .channel-category-tab li:not(.active) a:hover {
                  background-color: #f3d0ce;
                  color: #FFFFFF
                }
              }
              .ads-table {
                background: #f6f6f6;
              }
            }
          }
        }
      }
    }
  }
}

// end of customization

.channel-form {
    display: flex;
    justify-content: space-between;
    margin: 20px 0;
    align-items: center;
    &.w-1200 form {
      max-width: 1200px;
    }
    form {
        width:100%;
        // max-width: 800px;
        max-width: 900px;
        .form-group {
            position: relative;
            width: 100%;
            input[type="text"] {
                width: 100%;
                background: #F4F2F2;
                border: 1px solid #E6E6E6;
                border-radius: 6px;
                color: #999999;
                font-weight: 400;
                font-size: 14px;
                padding: 13px 24px;
            }
            ::-webkit-input-placeholder {  
                color: #999999;
                opacity: 1;
              }
              
              :-ms-input-placeholder {  
                color: #999999;
                opacity: 1;
              }
              
              ::placeholder {
                color: #999999;
                opacity: 1;
              }
            button[type="submit"] {
                background-color: transparent;
                border: none;
                position: absolute;
                top: 50%;
                right: 10px;
                transform: translateY(-50%);
                cursor: pointer;
                img {
                  width: 24px;
                  height: 24px;                }
            }
        }
    }
}
.Scheduling{
    .table, .table-pagination {
        width:100% !important;
        // max-width: 900px;
    }
    .w-1200 {
        // max-width: 1300px;
        th[width="55%"] {
          width: 65%;
        }
        th[width="30%"] {
          width: 20%;
        }
    }
    tr {
      >th, td {
        &:last-child {
          text-align: right;
        }
      }
      td {
        cursor: pointer;
        b {
          color: #5e5e5e;
          text-transform: none;
        }
        .edit-icon img {
          width: 24px;
          height: 24px;
        }
        .list-view-icon img{
          width: 40px !important;
          height: 40px !important;
        }
        .list-view-btn {
          margin-right: 68px;
          margin-left: 12px;
        }
      }
    }
}
.schedule-details {
  table {
    tr {
      td {
        border-bottom: none !important;
        padding: 5px !important;
        font-size: 11px;
      }
    }
  }
}
.scheduling-table {
  width: 100%;
  height: 100%;
  tbody {
    tr {
      display: flex;
      cursor: pointer;
      td {
        width: 100%;
        transition: border 0.1s linear;
      }
    }
    tr:hover {
      background: linear-gradient(#2E3033, #3e3b3b);
      border: 1px solid transparent; /* Transparent border for the gradient */
      border-image: linear-gradient(to right, #22bbbe, #7dad41, #f4b719, #e55824, #b52c80) 1;
    }
  }
}
.content-wrapper {
  height: 500px;
  overflow-y: auto;
}
.content-wrapper::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
  border-radius: 10px;
  // background-color: #F5F5F5;
}
.content-wrapper::-webkit-scrollbar {
  width: 4px;
}

.content-wrapper::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);
  background-color: #D22F26;
}

.vertical-line {
  width: 1px;
  height: 75px;
  border-left: 1px dashed #ccc; 
  margin: 0 20px;
  align-self: center;
}
.btn-circle {
  width: 40px;
  height: 40px;
  border-radius: 22%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 20px;
  border: none !important;
  background-color: #444649 !important;
}
.no-list-data{
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100px;
}
.no-data {
  height: 500px;
  width: 470px;
  font-size: 1.5rem;
  color: #888;
  font-weight: bold;
  vertical-align: middle;
}
.react-time-picker__clear-button {
  display: none;
}
.table-content2{
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: flex-start;
  height: 40px;
}

.channel-listing-cseq {
  height: 570px;
    &.top {
    }
    .list-wrapper {
      top: 25px;
      position: relative;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      gap: 8px;
      display: inline-flex;
      padding-bottom: 20px;
      box-sizing: border-box;
      width: 100%;
      .list-item {
        padding: 8px;
        justify-content: flex-start;
        align-items: center;
        // gap: 20px;
        gap: 8px;
        display: flex;
        background: transparent;
        border-radius: 5px;
        width: 100%;
        cursor: pointer;
        min-height: 40px;
        .channel-icon {
          width: 40px;
          height: 40px;
          position: relative;
        }
        .channel-name {
          width: 194px;
          font-size: 20px;
          font-family: Roboto;
          font-weight: 400;
          line-height: 1;
          overflow-wrap: break-word;
        }
        .channel-arrow {
          transform: rotate(-90deg);
          width: 20px;
          height: 20px;
          background-image: url("../../../assets/images/expand_more.svg");
          background-position: center;
          background-size: contain;
          background-repeat: no-repeat;
          position: absolute;
          // right: 10px;
          right: 0px;
        }
      }
      .item-wrapper {
        display: block;
        height: auto;
        width: 100%;
        .nexted-list {
          list-style: none;
          display: flex;
          flex-direction: column;
          display: none;
          margin: 0;
          // width: 210px !important;
          width: 100% !important;
          cursor: pointer;
          margin: 10px 0;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          max-height: 300px;
          // padding: 0px 10px;
          padding: 0px 20px;
          overflow-y: auto;
          direction: ltr;
          .nexted-list-item {
            padding: 5px 5px;
            margin-bottom: 2px;
            margin-top: 5px;
            border-radius: 5px;
            cursor: pointer;
            .program-icon {
              width: 30px;
              height: 30px;
              position: relative;
              margin-right: 10px;
            }
            .program-name {
              // width: 182px;
              width: 200px;
              font-size: 14px;
              font-family: Roboto;
              font-weight: 400;
              line-height: 1;
              display: inline-block;
              text-overflow: ellipsis;
              overflow: hidden;
              white-space: nowrap;
            }
          }
        }
        .nexted-list::-webkit-scrollbar-track {
          -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
          border-radius: 10px;
        }
        .nexted-list::-webkit-scrollbar {
          width: 4px;
        }
        
        .nexted-list::-webkit-scrollbar-thumb {
          border-radius: 10px;
          -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);
          background-color: #D22F26;
        }
        &.active {
          .list-item {
            .channel-arrow {
              transform: rotate(0deg);
              width: 20px;
              height: 20px;
              background-position: center;
              background-size: contain;
              background-repeat: no-repeat;
              position: absolute;
              // right: 10px;
              right: 0px;
            }
          }
          .nexted-list {
            display: inline-block;
            margin-top: 10px;
          }
        }
      }
    }
  }
  .schedule-detail-container-scroll {
    overflow: auto;
  }
  .schedule-detail-container-scroll::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
    border-radius: 10px;
  }
  .schedule-detail-container-scroll::-webkit-scrollbar {
    width: 4px;
    height: 4px;
  }
  .schedule-detail-container-scroll::-webkit-scrollbar-thumb {
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);
    background-color: #D22F26;
  }
  .dot {
    width: 12px;
    height: 12px;
    background-color: #161616;
    border-radius: 50%;
    margin: 10px 4px;
  }