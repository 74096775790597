.tooltip-wrapper {
    position: relative;
    display: inline-block;
  }
  
  .custom-tooltip {
    position: fixed;
    background-color: black;
    color: white;
    padding: 16px 18px;
    border-radius: 4px;
    font-size: 15px;
    white-space: nowrap;
    z-index: 9999;
    opacity: 0;
    visibility: hidden;
    transition: opacity 0.1s ease, visibility 0.1s ease;
    pointer-events: none;  /* Tooltip ke upar hover nahi hoga */
  }
  
  .tooltip-wrapper:hover .custom-tooltip {
    opacity: 1;
    visibility: visible;
  }
  