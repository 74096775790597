.drawer {
  position: fixed;
  top: 0;
  right: -100%;
  width: 544px;
  height: 100%;
  background-color: #1A1A1A;
  z-index: 999;
  transition: right 0.3s ease-in-out;
  box-shadow: -2px 0 5px rgba(0, 0, 0, 0.2);

  &.open {
    right: 0;
  }
}


.drawer-content {
  padding: 20px;
  overflow-y: auto;
}

.close-btn {
  width: 36px;
  height: 36px;
  font-size: 24px;
  background: #444649;
  border: none;
  cursor: pointer;
  color: #fff;
  position: absolute;
  right: 10px;
margin-right: 40px;
  border-radius: 5px;
  transition: 0.3s;
}