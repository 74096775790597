@import "../../assets/css/variables.scss";
.error-popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent background */
  z-index: 999; /* Ensure it's on top of other content */
}

.error-content {
  width: 400px;
  height: auto;
  background-color: white;
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
  text-align: center;
}

.close-button {
  margin-bottom: 0px;
  background: #d22f26;
  border-radius: 8px;
  padding: 12px 24px;
  color: #ffffff;
  line-height: 24px;
  font-size: 16px;
  font-weight: 500;
  border: none;
  transition: all ease-in-out 0.5s;
}

.error-message {
  height: 50%;
  font-size: 16px;
  margin: 10px 0;
  padding: 20px 0px;
  line-height: 24px;
  font-size: 16px;
  font-weight: 500;
}
.errorimg {
  img {
    height: 50px;
  }
}

.light {
  .modal-content {
    background-color: $light_popup;
  }
  .modal-header {
    .modal-title {
      color: $light_heading_text;
    }
    .btn-close {
      filter: invert(0);
    }
  }
  .modal-body {
    .form-label {
      color: $light_heading_text;
    }
  }
}
.dark {
  .modal-content {
    // background-color: $dark_popup;
  }
  .modal-header {
    .modal-title {
      color: $dark_heading_text;
    }
    .btn-close {
      filter: invert(1);
    }
  }
  .modal-body {
    .form-label {
      color: $dark_heading_text;
    }
  }
}
