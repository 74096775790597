.header-section {
    padding: 16px 40px;
    display: flex;
    justify-content: space-between;
    background-color: #131313;
    .logo {
        max-width: 135px;
        img {
            max-width: 100%;
        }
    }
    
    .right-side {
        display: flex;
        align-items: center;
        .need-help {
       
            margin-right: 10px;
            a {
                color: #ffffff;
              background-color: #FAEAE914;
                font-weight: 500;
                font-size: 16px;
                padding: 10px 24px;
                line-height: 24px;
                display: inline-block;
                border:   1px solid #D22F26;
                border-radius: 10px;
                &:hover {
                    color:#D22F26;
                }
            }
        }
        .contact-us {
            .btn {
                background: #D22F26;
                border-radius: 8px;
                padding: 12px 24px;
                color: #ffffff;
                line-height: 24px;
                font-size: 16px;
                font-weight: 500;
                border: none;
                transition: all ease-in-out 0.5s;
                &:hover {
                    background-color:#950e07;
                    color: #ffffff;
                }
            }
        }
    }
   
 }
 .overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5); /* Semi-transparent black */
    backdrop-filter: blur(8px); /* Blur effect */
    z-index: 999; /* Below the drawer */
    pointer-events: auto;
    transition: opacity 0.3s ease-in-out;
  }