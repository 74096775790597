.top-content {
    .left-side {
        .breadcrumbs {
            ul {
                margin: 0px 0px 0px;
            }
        }
    }
    .right-side {
        .icon-list {
            .search-box {
                border-radius: 6px;
            }
        }
    }
}

.channel-category {
    width: 100%;
    margin-top: 30px;
    display: flex;
    ul {
        padding: 0;
        list-style: none;
        display: flex;
        gap: 24px;

        li {
            display: inline-block;
            position: relative;

            a:not(.btn-light-red-ml-auto) {
                color: #999999;
                padding: 8px 16px;
                border: 0.5px solid #E6E6E6;
                border-radius: 6px;
                display: inline-block;
                background: #FFFFFF;
            }

            &.active {
                a {
                    border-color: #D22F26;
                    box-shadow: 0px 4px 16px rgba(153, 153, 153, 0.08);
                    color: #333333;
                    font-weight: 500;
                }

                &::before {
                    content: "";
                    width: 0;
                    height: 0;
                    border-style: solid;
                    border-width: 0 10px 10px 10px;
                    border-color: transparent transparent #ffffff transparent;
                    position: absolute;
                    bottom: -16px;
                    left: 50%;
                    transform: translateX(-50%);
                }
            }
        }
        + ul {
            margin-right: 0;
            margin-left: auto;
            width: 220px;
        }
    }
}
.dark-theme {
    .search-box {
        background-color: #1a1a1a !important;
        input {
            background-color: #1a1a1a !important;
            color: #ffffff;
        }
        img {
            filter: invert(1);
        }
    }
    .breadcrumbs ul li.active {
        color: #c3c3c3;
    }
    .channel-category  ul li {
        
        &.active {
            a {
                border-color: #D22F26;
            }
            &::before {
                border-color: transparent transparent #121212 ;
            }
        }
        a {
            background: #1a1a1a;
            border-color: #808080;
            color: #c3c3c3;
            &:hover {
                color: #fff;
            }
        }
    }
    .channel-content {
        background: #121212;
        .channel-top {
            .left-side ul li {
                &.active {
                    color: #dbdbdb;
                }
                // &:hover {
                //     color: #fff;
                // }
                 &:hover {
                    color: #fff;
                }
            }
            .right-side.browse-btns .mx-2 {
                background: #444649;
               border-color: #444649;
              //  color: #c3c3c3;
                img {
                //    filter: invert(0.75);
                }
            }
            .right-side:not(.browse-btns) {
                background: #444649;
            }
        }
    }
    .video-channel-list .block {
        border-color: #352555;
        .text-block {
            color: #fdf9f9;
        }
    }
    .right-content {
        background: #121212;
        border-color: #19191a;
        .video-title, .detail-label {
            color: #c3c3c3;
        }
        .detail-inputs {
            background: #1c1c1c!important;
            border-color: #19191a;
            color: #ffffff;
        }
    }
}
.margin {
    margin: 0;
}
.channel-detail {
    .search-box {
        padding: 6px;
        min-width: 310px;
    }

}

.channel-content {
    background: #FFFFFF;
    box-shadow: 0px -2px 4px rgba(153, 153, 153, 0.08);
    border-radius: 6px;
    padding: 32px;
    overflow-y: auto;
    min-height: 711px;

    @media screen and (max-width:1400px) {

        min-height: 100%;
    }
//   /* For WebKit browsers (Chrome, Safari, Edge) */
// .channel-content::-webkit-scrollbar {
//     width: 2px; /* Adjust the width as needed */
// }

// .channel-content::-webkit-scrollbar-thumb {
//     background-color: #d2ad26; /* Red color */

// }

// .channel-content::-webkit-scrollbar-track {
//     background: #88c010; /* Background color for the track */
// }



    .channel-top {
        display: flex;
        justify-content: space-between;
        margin-bottom: 20px;

        .left-side {
            ul {
                display: flex;
                list-style: none;
                gap: 20px;
                padding: 0;
                margin: 0 0 20px;
                border-bottom: 2px solid #090808;

                li {
                    font-size: 16px;
                    color: #999999;
                    cursor: pointer;
                    padding: 6px;
                    position: relative;

                    &.active {
                        color: #333333;

                        &::after {
                            border-color: #D22F26;
                        }
                    }

                    &::after {
                        content: "";
                        display: inline-block;
                        border-bottom: 2px solid transparent;
                        position: absolute;
                        width: 100%;
                        bottom: -2px;
                        left: 0;
                    }
                }
            }
        }

        .right-side {
            display: flex;
            align-items: center;
            border-radius: 6px;
            border: 0.5px solid #444649;
            height: 36px;
            &.browse-btns {
                border: none;
                .mx-2 {
                    margin: 0 2px;
                    border: 0.5px solid #E6E6E6;
                    height: 36px;
                    line-height: 36px;
                    border-radius: 6px;
                    padding: 0 5px;
                    color: #333333;
                }
            }
            .btn {
                padding: 14px 24px;
                font-weight: 500;
                font-size: 16px;
                margin-right: 24px;

                &.btn-red {
                    background: #D22F26;
                    border-radius: 8px;
                    color: #ffffff;
                }

                &.btn-light-red {
                    background: #FAEAE9;
                    border-radius: 8px;
                    color: #D22F26;
                }

                &.btn-icon {
                    padding: 7px 6px 6px;
                    background: #F2F2F2;
                    border: 0.5px solid #E6E6E6;
                    border-radius: 6px;
                    line-height: 0;
                }

                &:last-child {
                    margin-right: 0;
                }
            }
        }
    }

    .content-wrapper {
        // overflow-y: hidden;
        .tab-content {
            display: none;

            &.active {
                display: block;
            }
        }
    }
    .content-wrapper::-webkit-scrollbar {
        display: none; /* For WebKit browsers (Chrome, Safari, Edge) */
    }
}

.video-channel-list {
    display: flex;

    flex-wrap: wrap;

    .block {
        cursor: pointer;

        padding: 2px;
        border-radius: 6px;
        border: 0.5px;
 background-color: #0D0D0D;
        margin: 0 36px 36px 0;
        width: 110px;
        height: 90px;
        // border: 1px solid #f6f1f1;
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        text-align: center;
        position: relative;
        transition: all 0.3s;
        &:hover {
            box-shadow: 0px 8px 16px 0px rgba(16, 24, 40, 0.08);
            background: linear-gradient(#000, #000) padding-box, linear-gradient(to right, #22BBBE, #7DAD41, #F4B719, #E55824, #B52C80) border-box;
            border-radius: 6px;
            border: 1px solid transparent;
            box-shadow: 0px 16px 32px 0px rgba(16, 24, 40, 0.16);
        }
        img {
            object-fit: contain;
            vertical-align: middle;
        }

        .img-block {
           width: 100%;
            height: 48px;
            padding: 12px 0;
            background: #0B2A33;
            border: 2 solid #0F3B47;
        }
        &.upload {
            position: relative;
        }
        .text-block {
            width: 100%;
overflow: hidden;
            font-family: Roboto;
            font-size: 10px;
            height: 30px;
            font-weight: 400;
            padding: 4px;
            letter-spacing: 0em;
            text-align: center;

        }
        .dropdown {
            position: absolute;
            right: 7px;
            top: 7px;
        
        
        }
    }
    .active{
        box-shadow: 0px 8px 16px 0px rgba(16, 24, 40, 0.08);
        background: linear-gradient(#061c22, #061b21) padding-box, linear-gradient(to right, #22BBBE, #7DAD41, #F4B719, #E55824, #B52C80) border-box;
        border-radius: 6px;
        border: 1px solid transparent;
        box-shadow: 0px 16px 32px 0px rgba(16, 24, 40, 0.16);
    }
}

.btn-light-red-ml-auto {

    margin-left: auto !important;
    border: none;
    margin: 0px 5%;
    font-weight: 600 !important;
    padding: 10px 14px;
    cursor: pointer;
    &.mr-0 {
        margin-right: 0;
    }
}
.add-row-btn {
    min-width: 105px;
}
.upload-grid {
    .folder-icon {
        height: 96px;
        width: 116px;
    }
    .label-box {
        height: 96px;
        margin-top: 8px;
    }
}

.delete-button {
    font-family: Roboto;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: left;

    img {
        width: 16px;
        height: 16px;

    }

    .img-box {
        width: 100%;
        padding: 1px 4px 2px 4px;
        border: 0.33px solid #E6E6E6;
        border-radius: 4px;
        margin-right: 8px;
    }
   
}
.uploading {
    position: absolute;
    background: rgba(255, 255, 255, 0.51);
    z-index: 5;
    width: 100%;
    height: 100%;
    .inner-circle {
        position: absolute;
        width: 90px;
        height: 70px;
        border-radius: 50%;
        background-color: lightgrey;
      }
    .percentage {
        color: #D22F26;
        font-size: 14px;
        font-weight: 500;
        line-height: 14px;
        position: absolute;
        top: 34px;
        left: 50%;
        width: 32px;
        margin-left: -16px;
        text-align: center;
        span {
            font-size: 10px;
        }
    }
    svg {
        transform: rotate(-90deg) scale(1);
    }
    .percent {
        stroke-dasharray: 100;
    }
}
.folder-path .view-list {
    display: flex;
    .icon{
        border-right: 0;
        border-radius: 6px 0 0 6px;
        padding: 6px;
        margin-right: 0;
        display: flex;
        align-items: center;
        height: 36px;
        border: 0.5px solid #e6e6e6;
        &.active {
            background: #d22f26;
            border-color: #d22f26;
            img {
                filter: brightness(0) saturate(100%) invert(100%) sepia(100%) saturate(0%) hue-rotate(288deg) brightness(102%) contrast(102%);
            }
        }
        &:last-child {
            border-radius: 0 6px 6px 0;
        }
    }
} 
.folder-path {
    display: flex;
    justify-content: space-between;
    line-height: 32px;
    vertical-align: middle;
}
/* Styles for the dropdown container */
.bulk-edit {
    margin-left: auto;
    width: 100px;
}

/* Styles for the dropdown button */
.dropdown-button {
    background: none;
    border: none;
    cursor: pointer;
    float: right;
  
}

/* Styles for the dropdown content */
.dropdown-content {
    width: 172px;
    padding: 0;
    display: none;
    position: absolute;
    background-color: #fff;
    box-shadow: 0px 8px 16px 0px rgba(16, 24, 40, 0.08);
    left: 0;
    z-index: 1;
    top: 15px;
    border-radius: 6px;
}

/* Styles for dropdown links */
.dropdown-content a {
    display: block;
    padding: 2px;
    text-decoration: none;
    color: #333;
    /* Text color */
    padding: 8px 16px;
}

/* Styles for dropdown links on hover */
.dropdown-content a:hover {
    background-color: #f2f2f2;
}

/* Show the dropdown when hovering over the button */
.dropdown:hover .dropdown-content {
    display: block;
}

// .meta-block {
//     position: relative;
//     border-radius: 6px;
//     border: 0.5px solid #E6E6E6;
//     background: #F7F7F7;
//     // width: 150px;
//     display: inline-flex;
//     padding: 2px;
//     flex-direction: column;
//     align-items: flex-start;

   
//     .img-area{
//         width: 88px;
// height: 48px;
// border-radius: 6px 6px 0px 0px;
// background: #F0E9F5;
//     }

  
//     .text-area{
//         display: flex;
// width: 88px;
// padding: 4px;
// flex-direction: column;
// align-items: flex-start;
// gap: 8px;
// border-radius: 0px 0px 6px 6px;
// background: var(--neutral-white, #FFF);
    
// color: #333;
// font-family: Roboto;
// font-size: 10px;
// font-style: normal;
// font-weight: 400;
// line-height: normal;
// }
// }

.image-container-poster {
    margin-left: auto;
}

.delete-poster {
    background-color: #0000007A;
  padding: 2px;
  border-radius: 3px;
   

    img {
        width: 13px;
    }
}
.w-435 {
    width: 435px;
}
.browse-header {
    width: 840px;
    border-bottom: solid 1px #eeeaea;
    padding-bottom: 10px;
    > div:first-child {
        line-height: 40px;
    }
    input {
        width: 160px;
        font-size: 14px;
    }
    .search-box {
        border-radius: 6px;
        padding-left: 5px;
        img, input {
            height: 30px;
        }
    }
}
.browse-modal .modal-header {
    .btn-close {
        margin-top: -20px;
    }
}
.folder-file-collection {
    display: flex;
    flex-wrap: wrap;
    &.list {
        flex-direction: row;
        .browse-file {
            width: 100%;
            padding: 8px;
            border-radius: 6px;
            border-bottom: 0.5px solid #E6E6E6;
            margin-right: 0;
            margin-bottom: 10px;
            flex-direction: row;
            .s3-heirarchy {
                font-size: 12px;
                text-transform: capitalize;
            }
            span {
                text-align: left;
                padding-left: 10px;
            }
        }
    }
}
.browse-file {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-right: 20px;
    margin-bottom: 10px;
    transition: all 0.3s;
    width: 80px;
    padding: 4px;
    transition: all 0.3s ease-in-out;
    svg {
        transition: transform 0.3s ease-in-out;
        margin-left: 6px;
    }
    &:hover {
        svg {
            transform: scale(1.2);
        }
    }
    cursor: pointer;
    > * {
        width: 100%;
    }
    span {
        text-align: center;
        word-break: break-word;
    }
    &.selected {
        background-color: #fff;
        border: dashed 2px #eee!important;
    }
}
.s3-heirarchy {
    font-size: 12px;
    text-transform: capitalize;
    padding-left: 5px;
    &:first-child {
        padding-left: 0;
    }
}

