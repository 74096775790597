

.popup-content {
 
  padding: 40px;
  width: 100%; 
  max-width: 550px; 
  height: auto;
  position: relative;
  padding: 30px;
  border-radius: 10px;
  overflow: visible; 
  display: flex;
  flex-direction: column;
  margin-top: 25px;
  margin: 0 auto;


}
.custom-input {
  height: 60px;
  padding: 10px;
  border: 1px solid #ccc; 
  border-radius: 5px; 
}

.custom-input{
  height: 55px;
  border: 1px solid #444648;
  border-radius: 6px;

}

.custom-input-email{
  height: 55px;
  border: 1px solid #444648;
  border-radius: 6px;

  appearance: none; 
  background-image: url('../../assets/svg/playlists/downArrow.svg');
  background-position: right 16px center;
  background-repeat: no-repeat;
  background-size: 12px; 
}

.heading-text {
  color: #fff;
  font-family: 'Roboto', sans-serif;
  font-size: 40px;
  margin-bottom: 20px;
  margin-top: 29px;
  align-items: center;
  align-self: center;
  font-weight: normal;
}

.textarea {
  min-height: 40px; /* Adjust to your desired minimum height */
  max-height: 150px; /* Adjust if you want a maximum height */
  transition: height 0.2s ease; /* Smooth transition on height change */
}

.form-subtext{  
   color: #BFBFBF;
  text-align: left;
  font-size: 14px;
  margin-bottom: 18px;
  text-align: left;
  flex-grow: 1;
  
  margin-top: 40px;

}
.form-lable{
  color: #BFBFBF;
  font-size: 14px;
  font-weight: 400;
}

.close-icon {
         
  cursor: pointer;         
  position: absolute;
  align-self: flex-end; 
  margin-left: 80;  
  margin-left: auto;            
  align-self: flex-end;   
  top: 10px;
right: 10px;   
}

.form-group-contact {
  margin-bottom: 15px;
  display: flex;
  justify-content: space-between;
}

.form-item {
  margin-right: 10px;
  flex-grow: 1;
  text-align: left;
}

.form-item label {
  font-weight: bold;
}

.form-item select {
  width: 100%;
  padding: 10px;
  border-radius: 4px;
  border: 1px solid #ccc;
}
.form-subtext {
  margin-bottom: 15px;
  text-align: left;
}

// .upload-dashline{
//   border: 1px dashed #E4827D ; 
//   padding: 10px;
//   border-radius: 10px;

//   align-items: center;
// }

.upload-dashline {
  position: relative;
  padding: 10px;
  width: 416px;
  border-radius: 8px; /* Properly round the corners */
  background: linear-gradient(#1A1A1A, #1A1A1A) padding-box, 
              repeating-linear-gradient(
                45deg,
                #e4827d 0 6px,
                transparent 6px 12px
              ) border-box; /* Create the dashed border effect */
  border: 1px solid transparent; /* Base border for background trick */
}







.file-upload {
  display: flex;
  align-items: center;
  padding: 8px;
  border: 0.2px solid #FFFFFF3D; 
  border-radius: 4px;
  background-color: #2E3033;
  position: relative;
  height:48px

}

.upload-icon {
  margin-right: 8px;
  font-size: 18px;
  color: #555;
}

.upload-text {
  margin-right: auto;
  font-size: 14px;
  color: #555;
}

.browse-button {
  background-color: #00000014; /* Existing background color */
  color: white; /* Text color */
  padding: 6px 20px; /* Existing padding */
  border-radius: 20px; /* Existing border radius */
  cursor: pointer; /* Pointer cursor on hover */
  border: 0.2px solid #FFFFFF3D; 
}


input[type="file"] {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  cursor: pointer;
}


.contact-form label {
  color: #999999;
  font-size: 14px;
  margin-bottom: 5px;
  display: block;
}

.contact-form input, .contact-form textarea, .contact-form select {
  width: 416px;
  padding: 10px;
  border: 1px solid #444648;
  border-radius: 6px;
  background-color: #333;
  color: #999999;
  height: 48px;
}

.contact-form input[type="file"] {
  border: none;
  background-color: transparent;
  color: #ccc;
}

.contact-form .submit-btn, .contact-form .close-btn {
  margin-top: 15px;
  padding: 15px 15px;
  border: none;
  cursor: pointer;
  border-radius: 4px;
  width: 100%;
}

.contact-form .submit-btn {
  background-color: #54130F;
  color: #fff;
  padding: 15px;
  border: none;
  cursor: pointer;
  border-radius: 4px;
  width: 418px;
  /* Enhanced hover effect for 3D feel */
  transition: transform 0.3s ease, background-color 0.3s ease;
}



.contact-form .submit-btn:hover {
  background-color: #2A0908;
  transform: translateY(-3px); /* Lift button on hover */
}

.contact-form .close-btn {
  background-color: #f44336;
  color: #fff;
  margin-left: 10px;
}

.container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: #222;
}

.form {
  background-color: #444;
  padding: 30px;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
}

.form h2 {
  margin-bottom: 20px;
  text-align: center;
  color: #fff;
}

.form input[type="submit"] {
  background-color: #007bff;
  color: white;
  padding: 12px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.form input[type="submit"]:hover {
  background-color: #0056b3;
}

.form p {
  text-align: center;
  margin-top: 10px;
}

.form a {
  color: #fff;
  text-decoration: underline;
}

.cross-icon{

}



// D22F26


//  .popup-overlay {
//   position: fixed;
//   top: 0;
//   left: 0;
//   right: 0;
//   bottom: 0;
//   background-color: rgba(0, 0, 0, 0.5);
//   display: flex;
//   align-items: center;
//   justify-content: center;
// }

// .popup-content {
//   position: relative;
//   background: #fff;
//   padding: 20px;
//   width: 400px;
//   border-radius: 8px;
// }

// .close-icon {
//   position: absolute;
//   top: 10px;
//   right: 10px;
//   cursor: pointer;
//   width: 24px;
//   height: 24px;
// }

// .upload-dashline {
//   position: relative;
//   display: flex;
//   align-items: center;
//   padding: 10px;
//   border: 1px dashed #ccc;
//   border-radius: 5px;
//   margin-top: 10px;
//   width: 100%;
//   height: 80px; // Adjust based on desired height for placeholder
//   justify-content: center;
//   text-align: center;
// }

// .attachment-preview {
//   position: absolute;

//   right: 5px;
//   width: 70px;
//   height: 70px;
// }

.attachment-container {
  display: flex;
  align-items: center;
  position: relative; /* This will help position the button */
  flex-grow: 1;
}


.thumbnail-preview {
  width: 50px; /* Adjust as needed */
  height: 50px; /* Adjust as needed */
  object-fit: cover;
  border-radius: 5px;
  margin-right: 10px; /* Space between image and button */
}
.cross-icon-button {
  background: #444649;
  border: none;
  cursor: pointer;
  color: #fff; 
  padding: 10; 
  position: absolute; /* Position it absolutely */
  right: 0; /* Align it to the right end */
  top: 50%; /* Center vertically */
  transform: translateY(-50%); /* Adjust for perfect centering */
}